import {useTeacherVerificationStore} from "../../../store/useTeacherVerificationStore.ts";
import {Language, Certificate, Education} from "../../../store/useTeacherVerificationStore.ts";
import axiosInstance from "../../../utils/axiosConfig.ts";

const serializeLanguages = (languages: Array<Language>) => {
    return JSON.stringify(languages);
};

const appendCertificatesToFormData = (certificates: Array<Certificate>, formData: FormData) => {
    certificates.forEach((certificate, index) => {
        formData.append(`certificates[${index}][certID]`, certificate.certID);
        formData.append(`certificates[${index}][certDescription]`, certificate.certDescription);
        formData.append(`certificates[${index}][certName]`, certificate.certName);
        formData.append(`certificates[${index}][issuedBy]`, certificate.issuedBy);
        formData.append(`certificates[${index}][issueDate]`, certificate.issueDate ? certificate.issueDate.toISOString().split('T')[0] : '');
        if (certificate.certFile) {
            formData.append(`certificates[${index}][certFile]`, certificate.certFile);
        }
    });
};


const appendEducationToFormData = (educations: Array<Education>, formData: FormData) => {
    educations.forEach((education, index) => {
        formData.append(`educations[${index}][universityName]`, education.universityName);
        formData.append(`educations[${index}][degree]`, education.degree);
        formData.append(`educations[${index}][degreeType]`, education.degreeType);
        formData.append(`educations[${index}][specialization]`, education.specialization);
        formData.append(`educations[${index}][startDate]`, education.startDate ? education.startDate.toISOString().split('T')[0] : '');
        formData.append(`educations[${index}][graduationDate]`, education.graduationDate ? education.graduationDate.toISOString().split('T')[0] : '');
        if (education.diplomaFile) {
            formData.append(`educations[${index}][diplomaFile]`, education.diplomaFile);
        }
    });
};


export const submitForm = async() => {
    const formStore = useTeacherVerificationStore();
    const formData = new FormData();

    formData.append('about[firstname]', formStore.formState.about.firstname);
    formData.append('about[lastname]', formStore.formState.about.lastname);
    formData.append('about[country]', formStore.formState.about.country);
    formData.append('about[address]', formStore.formState.about.address);
    formData.append('about[phone]', formStore.formState.about.phone);
    formData.append('about[subject]', formStore.formState.about.subject);

    formData.append('languages', serializeLanguages(formStore.formState.languages));

    if (formStore.formState.photo.documentFrontSide) {
        formData.append('photo[documentFrontSide]', formStore.formState.photo.documentFrontSide);
    }
    if (formStore.formState.photo.documentBackSide) {
        formData.append('photo[documentBackSide]', formStore.formState.photo.documentBackSide);
    }

    formData.append('description[description]', formStore.formState.description.description);
    formData.append('availability', JSON.stringify(formStore.formState.availability));
    formData.append('price[price]', formStore.formState.price.price);
    appendCertificatesToFormData(formStore.formState.certificates, formData);
    appendEducationToFormData(formStore.formState.educations, formData)

    if (formStore.formState.photo.profilePhoto) {
        formData.append('photo[profilePhoto]', formStore.formState.photo.profilePhoto);
    }
    console.log('Teacher data: ', Object.fromEntries(formData.entries()));
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        const response = await axiosInstance.post('/teacher', formData, config);
        const resdata = await response.data;
        console.log('Form submitted successfully, data: ', resdata);
    } catch (error) {
        console.error('Error submitting form', error);
    }
    localStorage.removeItem('teacherFormState');
};

