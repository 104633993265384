import { defineStore } from 'pinia'
import axios from 'axios'
import { ref } from 'vue'
import axiosInstance from '../utils/axiosConfig.ts';
import router from "../router";

export const useAuthStore = defineStore('user', () => {
    const clientId = ref<string>(import.meta.env.VITE_CLIENT_ID || "458608580833-6skisldr482b6hqmedo4gvihkhiq23iq.apps.googleusercontent.com")
    const clientSecret = ref<string>(import.meta.env.VITE_CLIENT_SECRET || "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")
    const REDIRECT_URL = ref<string>('postmessage');

    const authenticateWithGoogle = async (code: string, scope: string) => {
        try {
            const payload = new URLSearchParams();
            payload.append('code', code);
            payload.append('client_id', clientId.value);
            payload.append('client_secret', clientSecret.value);
            payload.append('redirect_uri', REDIRECT_URL.value);
            payload.append('grant_type', 'authorization_code');
            payload.append('scope', scope);
            console.log(payload)

            const { data: tokenData } = await axios.post(
                'https://oauth2.googleapis.com/token',
                payload.toString(),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )

            console.log("Token: ", tokenData.access_token)

            if (tokenData && tokenData.access_token) {
                const response = await axiosInstance.post('/auth/googlelogin', { token: tokenData.access_token })
                if(response){
                    console.log("Res from back: ", response.data)
                    return response.data
                }
            } else {
                console.error('Failed to obtain access token from back')
            }
        } catch (error: any) {
            console.error('Failed to exchange token or fetch user data', error.response ? error.response.data : error.message)
        }
    }


    const loginWithGoogle = async (response: any) => {
        console.log("Google sign in response code:", response)
        if (response.code) {
            try {
                const token: string = await authenticateWithGoogle(response.code, response.scope)
                if(token){
                    localStorage.setItem('token', token.toString())
                    await router.push('/home')
                }
            } catch (error) {
                console.error("Error fetching user data:", error)
            }
        }
    }


    return {
        clientId,
        clientSecret,
        authenticateWithGoogle,
        loginWithGoogle,
    }
})
