<template>
  <v-form class="pricing-form w-50">
    <v-label>Price per lesson (EUR) *</v-label>
    <v-text-field
        variant="outlined"
        v-model="price.price"
    ></v-text-field>
  </v-form>
</template>

<script setup lang="ts">
import {Pricing, useTeacherVerificationStore} from "../../../store/useTeacherVerificationStore.ts";
import {reactive} from "vue";
const formStore = useTeacherVerificationStore();


const price = reactive<Pricing>(formStore.formState.price)
</script>

<style scoped>
.pricing-form {
  background-color: #FAF9F6;
}
</style>
