<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer
          expand-on-hover
          rail
      >
        <v-list>
          <v-list-item
              prepend-avatar="https://randomuser.me/api/portraits/women/85.jpg"
              subtitle=""
              title="Sandra Adams"
          >
            <v-list-item-title>Name</v-list-item-title>
            <v-list-item-subtitle>{{props.email}}</v-list-item-subtitle>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-row class="dashboard-list d-flex flex-column">
            <v-col>
              <v-list-item prepend-icon="mdi-view-dashboard" title="Dashboard" value="dashboard"></v-list-item>
              <v-list-item prepend-icon="mdi-folder" title="Lessons" value="lessons"></v-list-item>
              <v-list-item prepend-icon="mdi-pencil" title="Homeworks" value="homeworks"></v-list-item>
              <v-list-item prepend-icon="mdi-certificate" title="Exams" value="exams"></v-list-item>
              <v-list-item prepend-icon="mdi-history" title="Statistics" value="statistics"></v-list-item>
              <v-list-item prepend-icon="mdi-account" title="Profile" value="profile"></v-list-item>
            </v-col>
            <v-col class="d-flex align-end">
              <v-list-item prepend-icon="mdi-logout" title="Logout" value="logout" @click="logout"></v-list-item>
            </v-col>
          </v-row>
        </v-list>
      </v-navigation-drawer>

      <v-main style="height: 95vh">
        <v-container fluid class="cont">
          <v-row class="first-row">
            <v-col :cols="9" class="component">
              <v-row class="stats">
                <v-col>
                  <h2>Dashboard</h2>
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-btn variant="outlined">
                    Full statistic
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="stats-container">
                <v-col class="average-mark-container">Average mark</v-col>
                <v-col>Feedbacks</v-col>
                <v-col>Stats per marks</v-col>
              </v-row>
            </v-col>
            <v-col class="component">
              <v-row class="next-lesson-title-row">
                <v-col>
                  <h4>Next lesson</h4>
                </v-col>
              </v-row>
              <v-row class="next-lesson-body-row d-flex align-center">
                <v-col class="d-flex justify-center">
                  <v-card class="next-lesson-card">
                    <v-card-title>
                      <div>Next lesson card</div>
                    </v-card-title>
                    <v-card-text>
                      <div>Name of student/teacher</div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn variant="outlined">Join meeting</v-btn>
                      <v-btn variant="outlined">Decline</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="second-row">
            <v-col class="component">
              <v-row>
                <v-col>
                  <h3>Courses & Topics</h3>
                </v-col>
                <v-col class="d-flex justify-end align-center ga-3">
                  <v-text-field label="Search course"
                                density="compact"
                                single-line
                                append-inner-icon="mdi-magnify"
                  ></v-text-field>
                  <v-btn>
                    All courses
                  </v-btn>
                  <v-btn icon="mdi-arrow-left"></v-btn>
                  <v-btn icon="mdi-arrow-right"></v-btn>
                </v-col>
              </v-row>
              <v-row class="user-dashboard-offered-courses-list d-flex">
                <v-col class="user-dashboard-offered-course">
                  <v-card>
                    First course
                    <v-card-item>
                      image
                    </v-card-item>
                    <v-card-title>
                      <v-row>
                        <v-col class="ma-3">
                          Advanced JavaScript course
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-subtitle>
                      <v-row>
                        <v-col class="ma-3">Continue</v-col>
                        <v-col class="d-flex justify-end ma-3">Author name</v-col>
                      </v-row>
                    </v-card-subtitle>
                      <v-row class="d-flex flex-column ma-3">
                        <v-row>
                          <v-col class="d-flex justify-center align-center">Lessons 7/10</v-col>
                          <v-col class="d-flex justify-center align-center">Assignment 8/12</v-col>
                        </v-row>
                        <v-row>
                          <v-col class="d-flex justify-center align-center">Last score: A</v-col>
                          <v-col class="d-flex justify-center align-center">Time: 20h</v-col>
                        </v-row>
                        <v-row>
                          <v-col>Progress percentage</v-col>
                        </v-row>
                      </v-row>
                  </v-card>
                </v-col>
                <v-col class="user-dashboard-offered-course">
                  <v-card>
                    Second course
                    <v-card-item>
                      <v-row class="d-flex justify-center">
                        <v-col class="d-flex justify-center">
                          image
                        </v-col>
                      </v-row>
                    </v-card-item>
                    <v-card-title>
                      <v-row>
                        <v-col class="ma-3">
                          Probability theory course
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-subtitle>
                      <v-row>
                        <v-col class="ma-3">Continue</v-col>
                        <v-col class="d-flex justify-end ma-3">Author name</v-col>
                      </v-row>
                    </v-card-subtitle>
                    <v-row class="d-flex flex-column ma-3">
                      <v-row>
                        <v-col class="d-flex justify-center align-center">Lessons 7/10</v-col>
                        <v-col class="d-flex justify-center align-center">Assignment 8/12</v-col>
                      </v-row>
                      <v-row>
                        <v-col class="d-flex justify-center align-center">Last score: A</v-col>
                        <v-col class="d-flex justify-center align-center">Time: 20h</v-col>
                      </v-row>
                      <v-row>
                        <v-col>Progress percentage</v-col>
                      </v-row>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="component" :cols="4">
              <v-row>
                <v-col>
                  <v-btn size="lg">Lessons</v-btn>
                  <v-btn size="lg">Homeworks</v-btn>
                </v-col>
                <v-col class="d-flex justify-end">
                    <v-btn>All lessons</v-btn>
                </v-col>
              </v-row>
              <v-row class="lessons-list">
                <v-row>
                  <v-col class="d-flex justify-center align-center">Date</v-col>
                  <v-col class="d-flex justify-center align-center">Lesson subject title</v-col>
                  <v-col class="d-flex justify-center align-center">Name of teacher</v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex justify-center align-center">Date</v-col>
                  <v-col class="d-flex justify-center align-center">Lesson subject title</v-col>
                  <v-col class="d-flex justify-center align-center">Name of teacher</v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex justify-center align-center">Date</v-col>
                  <v-col class="d-flex justify-center align-center">Lesson subject title</v-col>
                  <v-col class="d-flex justify-center align-center">Name of teacher</v-col>
                </v-row>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script setup lang="ts">

import router from "../../router/index.ts";

const logout = () => {
  localStorage.removeItem('token');
  router.push('/');
}

const props = defineProps({
  email: String,
})


</script>

<style>
.cont{
  max-width: 90vw;
  max-height: 90vh;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-self: self-end;
  border: 2px solid black;
  align-items: center;
}

.first-row{
  height: 34vh;
  width: 100%;
  border: 2px solid red;
}
.second-row{
  height: 56vh;
  width: 100%;
  border: 2px solid red;
}

.stats-container{
  width: 100%;
  border: 2px solid brown;
  height: 20vh;
}

.average-mark-container{
  border-radius: 20px;
}

.component{
  margin: 20px;
  border: 2px solid blue;
  border-radius: 10px;
}

.next-lesson-title-row{
  height: 20%;
  border: 2px solid rebeccapurple;
}

.next-lesson-body-row{
  height: 80%;
  border: 2px solid rebeccapurple;
}

.lessons-list{
  border: 2px solid yellowgreen;
  height: 40vh;
}


.dashboard-list{
  height: 85vh;
}
</style>