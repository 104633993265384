import {  ThemeDefinition  } from 'vuetify'

export type CustomTheme = {
    colors: {
        background: string,
        primary: string,
        secondary: string,
        error: string,
        info: string,
        success: string,
        warning: string,
    }
};

const vuetifyTheme: ThemeDefinition = {
        colors: {
            background: '#FAF9F6',
            primary: '#F6AE2D',
            secondary: '#33658A',
            error: '#B00020',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FB8C00',
        }
};

export default vuetifyTheme;