<template>
  <div class="selection-lang">
    <v-select
        v-model="selectedLanguage"
        :items="languages.map(l => l.value)"
        variant="outlined"
        class="select"
        hide-details
        density="compact"
    ></v-select>
  </div>
</template>

<script setup lang="ts">
import {ref, watch} from 'vue';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();
const selectedLanguage = ref<string>(locale.value);
const languages = [
  { text: 'English', value: 'en' },
  { text: 'Русский', value: 'ru' },
];

const setLanguage = (language: string) => {
  locale.value = language as any;
};

// Watch for changes in selectedLanguage and update locale
watch(selectedLanguage, (newLang) => {
  setLanguage(newLang);
});
</script>

<style>
.v-text-field.v-text-field--solo .v-input__control{
  min-height: 10px;
}
</style>
