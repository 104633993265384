

<template>
  <v-toolbar density="default" :color="props.toolbarColor">
    <v-container class=" d-flex">
      <v-toolbar-title>
        <RouterLink to="/" class="text-decoration-none">
          <span style="color: #F26419; filter: brightness(150%)" class="font-weight-bold text-lg-h5 title">Attestatum</span>
          <!--              <img src="../../assets/logo.svg" alt="">-->
        </RouterLink>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
          to="/verification"
          variant="plain"
          text="Become a tutor"
          color="primary"
          class="font-weight-bold"
      >
      </v-btn>

      <v-btn
          to="/home"
          variant="plain"
          text="My space"
          color="primary"
          class="font-weight-bold"
      >
      </v-btn>

      <v-btn
          to="/tutors"
          variant="plain"
          text="Teachers"
          color="primary"
          class="font-weight-bold"
      >
      </v-btn>

    </v-container>
  </v-toolbar>
</template>

<script setup lang="ts">

import {RouterLink} from "vue-router";

const props = defineProps({
  toolbarColor: {
    type: String,
    default: 'primary' // Set a default value for the toolbar color
  }
});
</script>

<style scoped>

</style>
