import { createI18n } from 'vue-i18n';

const messages = {
    en: {
        message: {
            hello: 'Grow with every new lesson',
            helloText: 'Step into a world where learning transcends borders and innovation fuels education. Our platform is more than just a digital space; it\'s a vibrant community where young minds thrive, parents stay engaged, and teachers inspire. ',
            // Add other English translations here
        },
        validation: {
            required: 'This field is required',
            email: 'This field must be a valid email',
            min: 'This field must be at least {length} characters',
            // Add other validation messages here
        }
    },
    ru: {
        message: {
            hello: 'Взростай с каждым новым уроком!',
            helloText: 'Сделайте шаг в мир, где обучение выходит за рамки границ, а инновации стимулируют образование. Наша платформа - это больше, чем просто цифровое пространство; это динамичное сообщество, где молодые умы процветают, родители остаются вовлеченными, а учителя вдохновляют.',
            // Add other French translations here
        },
        validation: {
            required: 'Ce champ est obligatoire',
            email: 'Ce champ doit être un email valide',
            min: 'Ce champ doit comporter au moins {length} caractères',
            // Add other validation messages here
        }
    }
};

const i18n = createI18n({
    locale: 'en', // set default locale
    messages,
});

export default i18n;
