<template>
  <v-form class="languages-form w-50">
    <v-row>
      <v-col>
        <v-btn @click="addLanguage">Add language</v-btn>
      </v-col>
    </v-row>
    <v-container v-for="(language, index) in languages" :key="index" class="language-container">
      <v-row class="d-flex align-center">
        <v-col>
          <v-label v-if="index === 0">Language spoken *</v-label>
          <v-label v-if="index > 0">Additional language spoken</v-label>
          <v-select
              v-model="language.language"
              :items="['English', 'Spanish', 'Italian', 'German', 'Russian', 'Estonian', 'Finnish']"
              variant="outlined"
          ></v-select>
        </v-col>
        <v-col v-if="index > 0" class="d-flex justify-end" cols="auto">
          <v-btn
              @click="removeLanguage(index)"
              icon="mdi-delete"
              flat
              color="background"
              rounded
          ></v-btn>
        </v-col>
      </v-row>
      <v-container v-if="languages[index].language" class="pa-0">
        <v-label>{{ languages[index].language }} proficiency level</v-label>
        <v-row class="ga-4 d-flex justify-center pt-4">
          <v-col v-for="(checkbox, idx) in proficiencyLevels" :key="idx" cols="2" class="d-flex flex-column justify-center align-center">
            <v-radio-group v-model="language.proficiency">
              <v-radio
                  :value="checkbox.value"
                  outlined
              ></v-radio>
              <v-label>{{ checkbox.label }}</v-label>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-form>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useTeacherVerificationStore } from "../../../store/useTeacherVerificationStore.ts";

const formStore = useTeacherVerificationStore();

const languages = ref<Array<{ language: string, proficiency: string }>>(formStore.formState.languages.length > 0 ? formStore.formState.languages : [{ language: '', proficiency: '' }]);
const proficiencyLevels = [
  { label: 'Beginner', value: 'beginner' },
  { label: 'Intermediate', value: 'intermediate' },
  { label: 'Advanced', value: 'advanced' },
  { label: 'Fluent', value: 'fluent' },
  { label: 'Native', value: 'native' }
];

const addLanguage = () => {
  languages.value.push({ language: '', proficiency: '' });
};

const removeLanguage = (index: number) => {
  languages.value.splice(index, 1);
};


// Watch the languages ref and update the store whenever it changes
watch(languages, (newLanguages) => {
  formStore.formState.languages = newLanguages;
}, { deep: true });

</script>

<style scoped>
.languages-form {
  background-color: #FAF9F6;
}
.language-container {
  margin-bottom: 16px;
}
</style>
