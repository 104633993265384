import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css' //icons
import vuetifyTheme from "./config/vuetify.theme.ts";
import { VDateInput } from 'vuetify/labs/VDateInput'
import { createPinia } from "pinia";
import vue3GoogleLogin from 'vue3-google-login'
import i18n from "./localization/i18n.ts";


const vuetify = createVuetify({
    components: {
        ...components, // Spread existing components
        VDateInput // Add VDateInput
    },
    directives,
    theme: {
        defaultTheme: 'myCustomTheme',
        themes: {
            myCustomTheme: vuetifyTheme,
        },
    }
})



const app = createApp(App)
app.use(router)
app.use(vuetify)
app.use(i18n)
app.use(createPinia())
app.use(vue3GoogleLogin, {
    clientId: '458608580833-6skisldr482b6hqmedo4gvihkhiq23iq.apps.googleusercontent.com'
})
app.mount("#app")
