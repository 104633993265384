

<template>
  <v-sheet class="mx-auto ma-10 signup" width="500">
  <v-row>
    <v-col class="d-flex justify-center ma-5">
      <h1 class="signup-title">
        Sign up
      </h1>
    </v-col>
  </v-row>
    <v-row>
      <v-col class="d-flex justify-center align-center ga-2 ma-3">
        <p>
          Already have an account?
        </p>
        <RouterLink to="/login">
          <span style="color: #187E89" class="font-weight-bold title">Log in</span>
        </RouterLink>
      </v-col>
    </v-row>

    <v-form fast-fail @submit.prevent="validateAndRegister">

      <v-text-field
          v-model="name"
          :rules="[nameRules.required]"
          label="Name"
          variant="solo"
          color="secondary"
          :error-messages="registerErrors"
      ></v-text-field>

      <v-text-field
          v-model="email"
          :rules="[emailRules.emailFormat, emailRules.required]"
          label="Email"
          variant="solo"
          color="secondary"
          :error-messages="registerErrors"
      ></v-text-field>

      <v-text-field
          v-model="password"
          :rules="[passwordRules.passwordFormat, passwordRules.required]"
          label="Password"
          variant="solo"
          color="secondary"
          :error-messages="registerErrors"
      ></v-text-field>

      <v-text-field
          v-model="confirmPassword"
          :rules="[confirmPasswordRules.requiredPasswordMatch]"
          label="Confirm password"
          variant="solo"
          color="secondary"
          :error-messages="registerErrors"
      ></v-text-field>

      <v-btn
          variant="outlined"
          color="secondary"
          class="mt-2"
          type="submit"
          block
      >
        Sign up
      </v-btn>
    </v-form>

    <v-container class="d-flex justify-center align-center pa-0 ma-6 mx-0">
      <v-divider thickness="1"></v-divider>
      <p class="mx-2">or</p>
      <v-divider thickness="1"></v-divider>
    </v-container>
    <v-container class="pa-0 d-flex justify-center">
      <GoogleLogin :client-id="clientId" :callback="loginWithGoogle" prompt auto-login>
        <v-btn
            variant="outlined"
            color="secondary"
            class="mt-2"
            type="button"
            block
        >
        <span class="d-flex ga-2">
          <span>
            <font-awesome-icon :icon="faGoogle" />
          </span>
          Continue with Google
        </span>
        </v-btn>
      </GoogleLogin>
    </v-container>
</v-sheet>
</template>

<script lang="ts">

import {ref} from "vue";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons'
import {registerUser} from "../../services/authService.ts";
import { storeToRefs } from "pinia";
import { GoogleLogin } from "vue3-google-login";
import { useAuthStore } from "../../store/useAuthStore.ts";

export default {
  setup(){
    const name = ref<string>('')
    const email = ref<string>('')
    const confirmPassword = ref<string>('')
    const password = ref<string>('')
    const registerErrors = ref<string[]>([])
    const userStore = useAuthStore()
    const { clientId } = storeToRefs(userStore)
    const { loginWithGoogle } = userStore

    const nameRules = {
      required: (value: string) => !!value || 'Name is required',
    };

    const emailRules = {
      required: (value: string) => !!value || 'Email is required',
      emailFormat: (value: string) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailPattern.test(value) || 'Wrong email format'
      },
    };

    const passwordRules = {
      required: (value: string) => !!value || 'Password is required',
      passwordFormat: (value: string) => value.length >= 8 || 'Password must contain at least 8 characters',
    };

    const confirmPasswordRules =  {
      requiredPasswordMatch: (value: string) => value === password.value || 'Passwords must match'
    };
    const validateAndRegister = async () => {
      registerErrors.value = []
      const requiredNameError = nameRules.required(name.value)
      const requiredEmailError = emailRules.required(email.value)
      const emailFormatError = emailRules.emailFormat(email.value)
      const requiredPasswordError = passwordRules.required(password.value)
      const passwordFormatError = passwordRules.passwordFormat(password.value)
      const passwordMatchError = confirmPasswordRules.requiredPasswordMatch(confirmPassword.value)
      if(typeof requiredNameError === 'string'){
        registerErrors.value.push(requiredNameError)
      }
      if(typeof requiredEmailError === 'string'){
        registerErrors.value.push(requiredEmailError)
      }
      if(typeof emailFormatError === 'string'){
        registerErrors.value.push(emailFormatError)
      }
      if(typeof requiredPasswordError === 'string'){
        registerErrors.value.push(requiredPasswordError)
      }
      if(typeof passwordFormatError === 'string'){
        registerErrors.value.push(passwordFormatError)
      }
      if(typeof passwordMatchError === 'string'){
        registerErrors.value.push(passwordMatchError)
      }
      if (registerErrors.value.length > 0) {
        console.error('Validation errors:', registerErrors.value);
        return;
      }
      try{
        await registerUser(name.value, email.value, password.value)
      } catch (err) {
        console.error('Error in sign up:', err)
      }
    }

    return {
      name,
      email,
      password,
      confirmPassword,
      validateAndRegister,
      nameRules,
      faGoogle,
      faFacebook,
      emailRules,
      passwordRules,
      confirmPasswordRules,
      registerErrors,
      loginWithGoogle,
      clientId
    }
  },
  components: {
    GoogleLogin,
    FontAwesomeIcon,
  }
}


</script>


<style scoped>
.signup{
  background-color: #FAF9F6;
}
</style>