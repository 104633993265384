<template>
  <v-sheet class="mx-auto ma-10 pa-10 login" max-width="600">
    <v-row>
      <v-col class="d-flex justify-center ma-5">
        <h1 class="signup-title">
          Log in
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center align-center ga-2 ma-3">
        <p>
          Create account
        </p>
        <RouterLink to="/signup">
          <span style="color: #187E89" class="font-weight-bold title">Sign up</span>
        </RouterLink>
      </v-col>
    </v-row>

    <v-form @submit.prevent="validateAndLogin">

      <v-text-field
          v-model="email"
          :rules="[rules.required, rules.emailFormat]"
          label="Email"
          variant="solo"
          placeholder="johndoe@gmail.com"
          color="secondary"
          :error-messages="emailErrors"
          class="py-2"
      ></v-text-field>

      <v-text-field
          v-model="password"
          :rules="[passwordRules.required]"
          label="Password"
          type="password"
          variant="solo"
          color="secondary"
      ></v-text-field>

      <v-container>
        <v-row>
          <v-col class="d-flex justify-start pa-0 ma-0">
            <RouterLink to="/home">
              <span style="color: #187E89" class="font-weight-bold title">Forgot your password?</span>
            </RouterLink>
          </v-col>
        </v-row>
      </v-container>

      <v-btn
          variant="outlined"
          color="secondary"
          class="mt-2"
          type="submit"
          block
      >
        Log in
      </v-btn>

      <div v-if="error" class="error-message">{{ error }}</div>
    </v-form>

    <v-container class="d-flex justify-center align-center pa-0 ma-6 mx-0">
      <v-divider thickness="1"></v-divider>
      <p class="mx-2">or</p>
      <v-divider thickness="1"></v-divider>
    </v-container>
    <v-container class="pa-0 d-flex justify-center">

      <GoogleLogin :client-id="clientId" :callback="loginWithGoogle" prompt auto-login>
        <v-btn
            variant="outlined"
            color="secondary"
            class="mt-2"
            type="button"
            block
        >
        <span class="d-flex ga-2">
          <span>
            <font-awesome-icon :icon="faGoogle" />
          </span>
          Continue with Google
        </span>
        </v-btn>
      </GoogleLogin>
    </v-container>
  </v-sheet>
</template>

<script lang="ts">
import { ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { storeToRefs } from 'pinia'
import {GoogleLogin} from 'vue3-google-login'
import { useAuthStore } from '../../store/useAuthStore.ts'
import { loginUser } from '../../services/authService.ts'
import {LoginResponse} from "../../services/models/LoginResponse.ts";
import router from "../../router/index.ts";
export default {
  setup() {
    const email = ref('')
    const password = ref('')
    const error = ref<string>('')
    const emailErrors = ref<string[]>([])
    const passwordErrors = ref<string[]>([])
    const userStore = useAuthStore()
    const { clientId } = storeToRefs(userStore)
    const { loginWithGoogle } = userStore


    const rules = {
      required: (value: string) => !!value || 'Email is required',
      emailFormat: (value: string) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailPattern.test(value) || 'Wrong email format'
      },
    }
    const passwordRules = {
      required: (value: string) => !!value || 'Password is required'
    }


    const validateCredendials = () => {
      emailErrors.value = []
      passwordErrors.value = []
      const requiredError = rules.required(email.value)
      const emailFormatError = rules.emailFormat(email.value)
      const passwordRequiredError = passwordRules.required(password.value)

      if (typeof requiredError === 'string') {
        emailErrors.value.push(requiredError)
      }
      if (typeof emailFormatError === 'string') {
        emailErrors.value.push(emailFormatError)
      }
      if(typeof passwordRequiredError === 'string'){
        passwordErrors.value.push(passwordRequiredError)
      }
      return !(emailErrors.value.length > 0 && passwordErrors.value.length > 0);
    }

    const validateAndLogin = async () => {
      if(validateCredendials()){
        try {
          console.log(email.value, password.value)
          const token: LoginResponse = await loginUser(email.value, password.value)
          if(token){
            localStorage.setItem('token', token.toString())
            await router.push('/home')
          }
        } catch (err) {
          console.error('Error logging in:', err)
          error.value = 'Login failed. Please check your credentials and try again.'
        }
      } else return;
    }

    return {
      email,
      password,
      error,
      emailErrors,
      passwordErrors,
      faGoogle,
      faFacebook,
      validateAndLogin,
      rules,
      passwordRules,
      loginWithGoogle,
      clientId,
    }
  },
  components: {
    GoogleLogin,
    FontAwesomeIcon,
  },
}
</script>

<style scoped>
.login {
  background-color: #FAF9F6;
}
.error-message {
  color: red;
  margin-top: 10px;
}
</style>
