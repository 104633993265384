import { reactive } from "vue";
import { defineStore } from "pinia";

export interface AboutFormState {
    firstname: string,
    lastname: string,
    country: string,
    address: string,
    phone: string,
    subject: string,
}

export interface Language {
    language: string,
    proficiency: string,
}

export interface Certificate {
    issuedBy: string,
    certName: string,
    certDescription: string,
    certID: string,
    issueDate: Date | null,
    certFile: File | null,
}

export interface Photo {
    profilePhoto: File | null,
    documentFrontSide: File | null,
    documentBackSide: File | null,
}

export interface Education {
    universityName: string,
    degree: string,
    degreeType: string,
    specialization: string,
    startDate: Date | null,
    graduationDate: Date | null,
    diplomaFile: File | null,
}

export interface Description {
    description: string,
}

export interface Availability {
        monday: string[],
        tuesday: string[],
        wednesday: string[],
        thursday: string[],
        friday: string[],
        saturday: string[],
        sunday: string[],
}

export interface Pricing {
    price: string,
}

interface FormState {
    about: AboutFormState,
    languages: Language[],
    certificates: Certificate[],
    photo: Photo,
    educations: Education[],
    description: Description,
    availability: Availability,
    price: Pricing,
}

const initialFormState: FormState = {
    about: {
        firstname: '',
        lastname: '',
        country: '',
        address: '',
        phone: '',
        subject: '',
    },
    languages: [],
    certificates: [],
    photo: {
        profilePhoto: null,
        documentFrontSide: null,
        documentBackSide: null,
    },
    educations: [],
    description: {
        description: '',
    },
    availability: {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],
    },
    price: {
        price: '',
    },
}

export const useTeacherVerificationStore = defineStore('teacherForm', () => {
    const formState = reactive(initialFormState)

    const saveFormState = () => {
        localStorage.setItem('teacherFormState', JSON.stringify(formState));
    };

    const loadFormState = () => {
        const savedState = localStorage.getItem('teacherFormState');
        if (savedState) {
            Object.assign(formState, JSON.parse(savedState));
        }
        formState.certificates.forEach(certificate => {
            certificate.issueDate = certificate.issueDate ? new Date(certificate.issueDate) : null;
        });
        formState.educations.forEach(language => {
            language.startDate = language.startDate ? new Date(language.startDate) : null;
            language.graduationDate = language.graduationDate ? new Date(language.graduationDate) : null;
        });
    };

    return {
        formState,
        saveFormState,
        loadFormState
    }
})
