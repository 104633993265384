<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {faChalkboardTeacher, faHandsHoldingChild, faGraduationCap} from "@fortawesome/free-solid-svg-icons";
export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faChalkboardTeacher: faChalkboardTeacher,
      faHandsHoldingChild: faHandsHoldingChild,
      faGraduationCap: faGraduationCap,
      iconColor: '#FFC382'
    };
  },
}
</script>
<template>
  <v-container class="d-flex flex-column justify-space-between">
    <v-col class="d-flex justify-center">
      <h1>How is work?</h1>
    </v-col>
    <v-row class="my-6 responsive-row d-flex">
      <v-col cols="12" md="4" class="d-flex">
        <v-card variant="elevated" class="h-100 mx-auto d-flex flex-column">
          <v-card-item>
            <div>
              <div class="text-overline mb-1 d-flex align-center justify-center ga-3 my-3">
                <span>
                  <font-awesome-icon :icon="faGraduationCap" size="5x" :color="iconColor" />
                </span>
              </div>
              <div class="text-h5 my-2 d-flex justify-center">
                Student
              </div>
              <div class="text-caption">
                Discover the wonders of education from the comfort of your home! Dive into exciting lessons tailored just for you, guided by dedicated teachers who bring learning to life. Explore, ask questions, and unleash your potential in our vibrant online classroom.
              </div>
            </div>
          </v-card-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="d-flex">
        <v-card variant="elevated" class="h-100 mx-auto d-flex flex-column">
          <v-card-item>
            <div>
              <div class="text-overline mb-1 d-flex align-center justify-center ga-3 my-3">
                <span>
                  <font-awesome-icon :icon="faHandsHoldingChild" size="5x" :color="iconColor" />
                </span>
              </div>
              <div class="d-flex justify-center text-h5 my-2">
                Parent
              </div>
              <div class="text-caption">
                Track your child's progress effortlessly as they excel in their educational journey. With our intuitive platform, you'll witness firsthand the growth and achievements of your young scholar. Stay connected with teachers, access valuable insights, and nurture your child's academic success every step of the way.
              </div>
            </div>
          </v-card-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="d-flex">
        <v-card variant="elevated" class="h-100 mx-auto d-flex flex-column">
          <v-card-item>
            <div>
              <div class="text-overline mb-1 d-flex align-center justify-center ga-3 my-3">
                <span>
                  <font-awesome-icon :icon="faChalkboardTeacher" size="5x" :color="iconColor" />
                </span>
              </div>
              <div class="d-flex justify-center text-h5 my-2">
                Teacher
              </div>
              <div class="text-caption">
                Ignite the spark of curiosity in young minds as you lead engaging online lessons. Be the guiding light in their educational voyage, fostering a love for learning that transcends boundaries. With our innovative platform, empower students to thrive academically and personally, making a lasting impact on their lives.
              </div>
            </div>
          </v-card-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

@media (min-width: 1000px) {
  .responsive-row {
    flex-wrap: nowrap !important;
  }
}
@media (max-width: 1000px) {
  .responsive-row {
    flex-direction: column;
    align-items: center;
  }
}
</style>
