<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: 'HomeRoles'
});




</script>
<template>
  <v-container class="w-100 h-40 d-flex justify-center align-content-lg-center">
    <v-col class="pa-0 d-flex flex-column justify-center align-center">
      <h1 class="my-10">Why us?</h1>
      <p class="why-us-text">
        Easy secure access to the high grade teachers would help to pass any school exams.
      </p>

      <v-list class="w-auto ma-16 responsive-list overflow-hidden" min-width="320" style="background-color: #FAF9F6">
        <v-list-item>
          <div class="d-flex align-center"> <!-- Flex container -->
            <img src="../../assets/check.png" width="30" height="30" alt="Image Alt Text">
            <v-col>
              <v-list-item-title class="text-lg-h6">Flexible Learning</v-list-item-title>
              <v-list-item-subtitle>Study at your own pace.</v-list-item-subtitle>
            </v-col>
          </div>
        </v-list-item>
        <v-list-item>
          <div class="d-flex align-center"> <!-- Flex container -->
            <img src="../../assets/check.png" width="30" height="30" alt="Image Alt Text">
            <v-col>
              <v-list-item-title class="text-lg-h6">High-Quality Teachers</v-list-item-title>
              <v-list-item-subtitle>Access to top-tier educators.</v-list-item-subtitle>
            </v-col>
          </div>
        </v-list-item>
        <v-list-item>
          <div class="d-flex align-center"> <!-- Flex container -->
            <img src="../../assets/check.png" width="30" height="30" alt="Image Alt Text">
            <v-col>
              <v-list-item-title class="text-lg-h6">Reusable Resources</v-list-item-title>
              <v-list-item-subtitle>Materials for all subjects.</v-list-item-subtitle>
            </v-col>
          </div>
        </v-list-item>
        <v-list-item>
          <div class="d-flex align-center"> <!-- Flex container -->
            <img src="../../assets/check.png" width="30" height="30" alt="Image Alt Text">
            <v-col>
              <v-list-item-title class="text-lg-h6">Secure Platform</v-list-item-title>
              <v-list-item-subtitle class="resp">Ensuring your data is protected.</v-list-item-subtitle>
            </v-col>
          </div>
        </v-list-item>
      </v-list>




<!--      <v-col class="d-flex justify-center">-->
<!--        <v-btn-->
<!--        to="/signup"-->
<!--        variant="outlined"-->
<!--        color="primary"-->
<!--        text="Get started"-->
<!--        size="large"-->
<!--        >-->
<!--        </v-btn>-->
<!--      </v-col>-->
    </v-col>
  </v-container>
</template>

<style scoped>
.why-us-text{
  text-align-last: left;
}
</style>