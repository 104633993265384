<template>
  <v-form class="description-form w-50">
    <v-row>
      <v-col>
        <v-label>Profile description (optional)</v-label>
        <v-textarea
            variant="outlined"
            v-model="description.description"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-form>
</template>


<script setup lang="ts">
import {reactive} from 'vue'
import { Description, useTeacherVerificationStore } from "../../../store/useTeacherVerificationStore.ts";
const formStore = useTeacherVerificationStore();
const description = reactive<Description>(formStore.formState.description)

</script>

<style>
.description-form{

}
</style>