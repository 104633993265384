<template>
  <v-form class="education-form w-50">
    <v-row>
      <v-col>
        <v-btn @click="addEducation">Add education</v-btn>
      </v-col>
    </v-row>
    <v-container v-for="(education, index) in educations" :key="index" class="education-container">
      <v-row class="d-flex align-center">
        <v-col>
          <v-label>University *</v-label>
          <v-text-field
              v-model="education.universityName"
              variant="outlined"
          >
          </v-text-field>
        </v-col>
        <v-col class="d-flex justify-end" cols="auto">
          <v-btn
              @click="removeEducation(index)"
              icon="mdi-delete"
              flat
              color="background"
              rounded
          ></v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-label>Degree *</v-label>
          <v-text-field
              v-model="education.degree"
              variant="outlined"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-label>Degree type *</v-label>
          <v-select
              :items="['Bachelor', 'Master', 'PhD']"
              variant="outlined"
              v-model="education.degreeType"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-label>Specialization *</v-label>
          <v-text-field
              v-model="education.specialization"
              variant="outlined"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-label>Enrollment date *</v-label>
          <v-date-input
              variant="outlined"
              v-model="education.startDate"
              @change="handleStartDate($event, index)"
          ></v-date-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-label>Graduation date *</v-label>
          <v-date-input
              variant="outlined"
              v-model="education.graduationDate"
              @change="handleEndDate($event, index)"
          ></v-date-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-label>Upload diploma(optional)</v-label>
          <v-file-input
              variant="outlined"
              v-model="education.diplomaFile"
              @change="updateDiplomaFile($event, index)"
          ></v-file-input>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script setup lang="ts">
import {ref, watch} from 'vue';
import { useTeacherVerificationStore } from "../../../store/useTeacherVerificationStore.ts";


const formStore = useTeacherVerificationStore()
const educations = ref<Array<{
  universityName: string,
  degree: string,
  degreeType: string,
  specialization: string,
  startDate: Date | null,
  graduationDate: Date | null,
  diplomaFile: File | null,
}>>
(formStore.formState.educations.length > 0 ? formStore.formState.educations: [{
  universityName: '',
  degree: '',
  degreeType: '',
  specialization: '',
  startDate: null,
  graduationDate: null,
  diplomaFile: null,
}])

const addEducation = () => {
  educations.value.push({ universityName: '', degree: '', degreeType: '', specialization: '', startDate: null, graduationDate: null, diplomaFile: null})
}

const removeEducation = (index: number) => {
  educations.value.splice(index, 1)
}

const handleStartDate = (event: any, index: number) => {
  const date = event.target.value;
  educations.value[index].startDate = date ? new Date(date) : null;
};

const handleEndDate = (event: any, index: number) => {
  const date = event.target.value;
  educations.value[index].graduationDate = date ? new Date(date) : null;
};


const updateDiplomaFile = (event: any, index: number) => {
  const file = event.target.files[0];
  educations.value[index].diplomaFile = file ? file : null;
}

watch(educations, (newEducation) => {
  formStore.formState.educations = newEducation;
}, { deep: true });

</script>

<style scoped>
.education-form {
  background-color: #FAF9F6;
}
</style>