<script setup lang="ts">
</script>

<template>
  <v-container class="hero-container d-flex justify-center align-center w-100 h-100 pa-0">
    <v-card
        variant="flat"
        color="transparent"
        class="d-flex flex-column h-75 w-75 align-self-end responsive-card"
    >
      <v-card-item class="w-100 pa-2 d-flex responsive-content">
        <v-row class="d-flex flex-column">
          <v-col class="mb-4 responsive-hero d-flex justify-center" style="color: #F6AE2D">
            <h2>
              {{ $t('message.hello') }}
            </h2>
          </v-col>
          <v-col class="w-100 d-flex justify-center">
            <p class="w-75 font-weight-medium responsive-text" style="color: #ffffff">
              {{ $t('message.helloText') }}
            </p>
          </v-col>
        </v-row>
      </v-card-item>
    </v-card>
  </v-container>
</template>


<style scoped>
.responsive-hero h2 {
  font-size: 3rem;
  font-family: initial;
  text-align: center;
  margin: 0;
}

.responsive-text {
  font-size: 1.2rem;
  text-align: center;
}

@media (max-width: 1200px) {
  .responsive-hero h2 {
    font-size: 2.25rem;
  }

  .responsive-text {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .responsive-hero h2 {
    font-size: 2rem;
  }

  .responsive-text {
    font-size: 1rem;
    width: 100%;
    padding: 0 10px;
  }
}

@media (max-width: 480px) {

  .responsive-hero h2 {
    font-size: 2rem;
    text-align: center;
  }

  .responsive-text {
    font-size: 0.9rem;
    width: 100%;
    padding: 0 10px;
  }
}
</style>
