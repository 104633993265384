<template>
  <v-container>
    <v-card class="days-container flex-wrap px-0">
      <v-row class="days d-flex flex-row align-center">
        <v-row class="days-row d-flex flex-row align-center justify-space-evenly mx-3">
          <v-col v-for="day in Object.keys(availabilities)" class="daySheets d-flex justify-center align-center">
            <div class="text-h6">{{ capitalize(day) }}</div>
          </v-col>
        </v-row>
      </v-row>
      <v-form class="scrollable-container availability-form">
        <v-row class="d-flex mt-6 px-3">
          <v-col v-for="(slots, day) in availabilities" :key="day" class="slots-col d-flex">
            <v-row class="d-flex" v-if="slots">
              <v-col v-for="slot in allSlots" :key="slot" :class="{'slot-selected': slots.includes(slot)}" class="slot d-flex justify-center px-0">
                <v-btn flat color="transparent" @click="toggleSlot(day, slot)">
                  {{ slot }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>


<script setup lang="ts">
import {ref, watch} from 'vue';
import { Availability, useTeacherVerificationStore } from "../../../store/useTeacherVerificationStore.ts";
const formStore = useTeacherVerificationStore();

const availabilities = ref<Availability>({
  monday: formStore.formState.availability.monday || [],
  tuesday: formStore.formState.availability.tuesday || [],
  wednesday: formStore.formState.availability.wednesday || [],
  thursday: formStore.formState.availability.thursday || [],
  friday: formStore.formState.availability.friday || [],
  saturday: formStore.formState.availability.saturday || [],
  sunday: formStore.formState.availability.sunday || [],
});


const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const allSlots = [
  '00:00-01:00', '01:00-02:00', '02:00-03:00', '03:00-04:00',
  '04:00-05:00', '05:00-06:00', '06:00-07:00', '07:00-08:00',
  '08:00-09:00', '09:00-10:00', '10:00-11:00', '11:00-12:00',
  '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00',
  '16:00-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00',
  '20:00-21:00', '21:00-22:00', '22:00-23:00', '23:00-00:00',
];

const toggleSlot = (day: keyof Availability, slot: string) => {
  const daySlots = availabilities.value[day];
  if (daySlots.includes(slot)) {
    availabilities.value[day] = daySlots.filter((s: string) => s !== slot);
  } else {
    availabilities.value[day].push(slot);
  }
};

watch(availabilities, (newSLots) => {
  formStore.formState.availability = { ...newSLots };
}, { deep: true });
</script>

<style>
.days {
  background-color: #d9534f;
  height: 6vh;
  position: sticky;
  top: 0;
  z-index: 1;
}

.scrollable-container {
  overflow-y: scroll;
  scrollbar-color: black #faf9f6;
  scrollbar-width: auto;
  overflow-x: hidden;
  display: flex;
  z-index: 1;
  height: 50vh;
}

.slot {
  cursor: pointer;
}

.slot-selected {
  background-color: #f6ae2d;
}

.slots-col {
  border-left: 1px solid lightgrey;
}
</style>
