import axiosInstance from '../utils/axiosConfig.ts';
import { isAxiosError } from 'axios';
import {RegisterResponse} from "./models/RegisterRequest.ts";
import { LoginResponse } from "./models/LoginResponse.ts";

interface ErrorResponse {
    message: string;
}

export const loginUser = async (email: string, password: string): Promise<LoginResponse> => {
    try {
        const response = await axiosInstance.post<LoginResponse>('/auth/login', {
            email: email,
            password: password
        });
        console.log("Login token: ", response.data)
        return response.data;
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            const errorData = error.response.data as ErrorResponse;
            throw new Error(errorData.message || 'Failed to login. Axios error.');
        }
        // For non-Axios errors
        throw new Error((error as Error).message || 'Failed to login. Non axios error.');
    }
};

export const registerUser = async (name: string, email: string, password: string): Promise<RegisterResponse> => {
    try {
        const response = await axiosInstance.post<RegisterResponse>('/auth/signup', {
            name: name,
            email: email,
            password: password
        });
        console.log(response.data)
        return response.data;
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            const errorData = error.response.data as ErrorResponse;
            throw new Error(errorData.message || 'Failed to login');
        }
        throw new Error((error as Error).message || 'Failed to login');
    }
};