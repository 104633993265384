<template>
  <v-form class="about-form w-50">
    <v-label>First name *</v-label>
    <v-text-field
        variant="outlined"
        v-model="about.firstname"
    ></v-text-field>
    <v-label>Last name *</v-label>
    <v-text-field
        variant="outlined"
        v-model="about.lastname"
    ></v-text-field>
    <v-label>Country of origin *</v-label>
    <v-select
        :items="['Estonia', 'USA', 'Spain', 'Georgia', 'Finland', 'China']"
        variant="outlined"
        v-model="about.country"
    ></v-select>
    <v-label>Address</v-label>
    <v-text-field
        variant="outlined"
        v-model="about.address"
    ></v-text-field>
    <v-label>Phone *</v-label>
    <v-text-field
        variant="outlined"
        v-model="about.phone"
    ></v-text-field>
    <v-label>Subject taught *</v-label>
    <v-select
        :items="['Math', 'Physics', 'English', 'Chemist', 'History', 'Art', 'Computer Science']"
        variant="outlined"
        v-model="about.subject"
    ></v-select>
  </v-form>
</template>

<script setup lang="ts">
import {AboutFormState, useTeacherVerificationStore} from "../../../store/useTeacherVerificationStore.ts";
import {reactive} from "vue";

const formStore = useTeacherVerificationStore();

const about = reactive<AboutFormState>(formStore.formState.about);

</script>

<style scoped>
.about-form {
  background-color: #FAF9F6;
}
</style>
