<template>
  <v-form class="photo-form w-50">
    <v-row>
      <v-col>
        <v-label>Upload your photo portrait *</v-label>
        <v-file-input
            variant="outlined"
            v-model="photo.profilePhoto"
            @change="updateTeacherPortraitPhoto($event)"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-label>Upload front side of document - ID or passport *</v-label>
        <v-file-input
            variant="outlined"
            v-model="photo.documentFrontSide"
            @change="updateDocumentFrontFile($event)"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-label>Upload back side of document - ID or passport *</v-label>
        <v-file-input
            variant="outlined"
            v-model="photo.documentBackSide"
            @change="updateDocumentBackFile($event)"
        ></v-file-input>
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup lang="ts">
import {reactive} from "vue";
import { Photo, useTeacherVerificationStore } from "../../../store/useTeacherVerificationStore.ts";

const formStore = useTeacherVerificationStore();

const photo = reactive<Photo>(formStore.formState.photo)

const updateTeacherPortraitPhoto = (event: any) => {
  const file = event.target.files[0]
  photo.profilePhoto = file ? file : null;
}

const updateDocumentFrontFile = (event: any) => {
  const file = event.target.files[0]
  photo.documentFrontSide = file ? file : null;
}
const updateDocumentBackFile = (event: any) => {
  const file = event.target.files[0]
  photo.documentBackSide = file ? file : null;
}
</script>

<style scoped>
.photo-form {
  background-color: #FAF9F6;
}
</style>