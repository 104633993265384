<template>
  <div class="stepper">

    <div class="stepper-header">
      <div
          v-for="step in steps"
          :key="step.value"
          :class="['stepper-item', { active: currentStep === step.value }]"
          @click="navigateToStep(step.value)"
      >
        {{ step.title }}
      </div>
    </div>

    <div class="stepper-content">
      <component :is="currentComponent" class="ma-auto"></component>
    </div>

    <div class="stepper-actions">
      <button v-if="currentStep > 1" @click="previousStep">Previous</button>
      <button v-if="currentStep < steps.length" @click="nextStep">Next</button>
      <button v-if="currentStep === steps.length" @click="handleSubmit">Submit</button>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, computed } from 'vue';
import { useTeacherVerificationStore } from "../../../store/useTeacherVerificationStore.ts";
import {submitForm} from "./submitForm.ts";
import About from './About.vue';
import Languages from './Languages.vue';
import Certificates from './Certificates.vue';
import Photo from './Photo.vue';
import Education from './Education.vue';
import ProfileDescription from '@/components/common/teacher/ProfileDescription.vue';
import Availability from '@/components/common/teacher/Availability.vue';
import Pricing from '@/components/common/teacher/Pricing.vue';

export default defineComponent({
  components: {
    Pricing,
    Certificates,
    Languages,
    About,
    Photo,
    Education,
    ProfileDescription,
    Availability,
  },
  setup() {
    const currentStep = ref(1);
    const teacherFormStore = useTeacherVerificationStore();
    const steps = [
      { title: 'About', value: 1, component: 'About' },
      { title: 'Languages', value: 2, component: 'Languages' },
      { title: 'Certificates', value: 3, component: 'Certificates' },
      { title: 'Photo', value: 4, component: 'Photo' },
      { title: 'Education', value: 5, component: 'Education' },
      { title: 'Description', value: 6, component: 'ProfileDescription' },
      { title: 'Availability', value: 7, component: 'Availability' },
      { title: 'Pricing', value: 8, component: 'Pricing' },
    ];

    const currentComponent = computed(() => steps.find(step => step.value === currentStep.value)?.component);

    const navigateToStep = (step: number) => {
      currentStep.value = step;
      teacherFormStore.saveFormState()
    };

    const nextStep = () => {
      if (currentStep.value < steps.length) {
        navigateToStep(currentStep.value + 1)
        // currentStep.value += 1;
      }
    };

    const previousStep = () => {
      if (currentStep.value > 1) {
        navigateToStep(currentStep.value - 1)
        // currentStep.value -= 1;
      }
    };

    const handleSubmit = () => {
      submitForm();
    };




    teacherFormStore.loadFormState()

    return {
      currentStep,
      steps,
      currentComponent,
      navigateToStep,
      nextStep,
      previousStep,
      submitForm,
      handleSubmit
    };
  },
});
</script>

<style scoped>
.stepper {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  background-color: #FAF9F6;
}

.stepper-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.stepper-item {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 30px;
}

.stepper-item.active {
  background-color: #007bff;
  color: white;
}

.stepper-content {
  flex-grow: 1;
}

.stepper-actions {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 20px;
}
</style>



//last page should have submit button
//each step is separate form which should be validated
//form filled data for each step should be persisted from local storage
//