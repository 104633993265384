<template>
  <v-form class="cert-form w-50">
      <v-row>
        <v-col>
          <v-btn @click="addCertificate">Add certificate</v-btn>
        </v-col>
      </v-row>
      <v-container v-for="(certificate, index) in certificates" :key="index" class="language-container">
        <v-row class="d-flex align-center">
          <v-col>
            <v-label>Issued by *</v-label>
            <v-text-field
                v-model="certificate.issuedBy"
                variant="outlined"
            >
            </v-text-field>
          </v-col>
          <v-col class="d-flex justify-end" cols="auto">
            <v-btn
                @click="removeCertificate(index)"
                icon="mdi-delete"
                flat
                color="background"
                rounded
            ></v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-label>Issue date *</v-label>
            <v-date-input
                v-model="certificate.issueDate"
                variant="outlined"
                @change="handleDate($event, index)"
            ></v-date-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-label>Certificate name *</v-label>
            <v-text-field
                v-model="certificate.certName"
                variant="outlined"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-label>Certificate ID *</v-label>
            <v-text-field
                v-model="certificate.certID"
                variant="outlined"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-label>Certificate description(optional)</v-label>
            <v-textarea
                v-model="certificate.certDescription"
                variant="outlined"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-label>Upload certificate *</v-label>
            <v-file-input
                variant="outlined"
                v-model="certificate.certFile"
                @change="updateCertFile($event, index)"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-container>
  </v-form>
</template>

<script setup lang="ts">
import {ref, watch} from 'vue';
import { useTeacherVerificationStore } from "../../../store/useTeacherVerificationStore.ts";
const formStore = useTeacherVerificationStore();

const certificates = ref<Array<{
  issuedBy: string,
  certName: string,
  certDescription: string,
  certID: string,
  issueDate: Date | null,
  certFile: File | null,
}>>
(formStore.formState.certificates.length > 0 ? formStore.formState.certificates : [{
  certID: '',
  certDescription: '',
  certName: '',
  issuedBy: '',
  issueDate: null,
  certFile: null }]);
const addCertificate = () => {
  certificates.value.push({ issuedBy: '', certName: '', certDescription: '', certID: '', issueDate: null, certFile: null })
}

const removeCertificate = (index: number) => {
  certificates.value.splice(index, 1)
}

const handleDate = (event: any, index: number) => {
  const date = event.target.value;
  certificates.value[index].issueDate = date ? new Date(date) : null;
};


const updateCertFile = (event: any, index: number) => {
  const file = event.target.files[0];
  certificates.value[index].certFile = file ? file : null;
}


watch(certificates, (newCertificates) => {
  formStore.formState.certificates = newCertificates;
}, { deep: true });

</script>

<style scoped>
.cert-form {
  background-color: #FAF9F6;
}
</style>