<script setup lang="ts">
import PersonalSpace from "../components/dashboard/PersonalSpace.vue";
import AuthHeader from "../components/dashboard/authHeader.vue";
import {onMounted, ref} from "vue";
import AxiosInstance from "../utils/axiosConfig.ts";

const userEmail = ref<string>('')

const getUserData = async () => {
  const token = await localStorage.getItem('token')

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const response = await AxiosInstance.get('/auth/me', config)
  console.log("Mounted ", response.data.email)

  userEmail.value = response.data.email
}

onMounted(getUserData)

</script>
<template>
  <AuthHeader toolbarColor="secondary"/>
  <PersonalSpace class="space" :email="userEmail"/>
</template>
<style>
.space{
  background-color: #FAF9F6;
}
</style>