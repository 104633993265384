import { createRouter, createWebHistory } from 'vue-router';
import HomePage from "../views/HomeView.vue";
import SignupView from "../views/SignupView.vue";
import TutorsListView from "../views/TeacherListView.vue";
import LoginView from "../views/LoginView.vue";
import PersonalSpaceView from "../views/PersonalSpaceView.vue";
import TutorVerifView from "../views/BecomeTeacher.vue";

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/signup',
        name: 'Signup',
        component: SignupView
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginView
    },
    {
        path: '/tutors',
        name: 'Tutors',
        component: TutorsListView
    },
    {
        path: '/home',
        name: 'PersonalSpace',
        component: PersonalSpaceView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/verification',
        name: 'TutorVerification',
        component: TutorVerifView
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, _from, next) => {
    if (to.meta.requiresAuth) {
        const token = localStorage.getItem('token');
        if (token) {
            // User is authenticated, proceed to the route
            next();
        } else {
            // User is not authenticated, redirect to login
            next('/');
        }
    } else {
        // Non-protected route, allow access
        next();
    }
});

export default router;
